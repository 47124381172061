type EventType =
  | 'saveAndOpenFile'
  | 'setWebViewTitle'
  | 'openWhatsapp'
  | 'getToken'
  | 'logErrors';

export function emitMobileAppEvent(
  event: EventType,
  payload?: string,
): boolean {
  if (!window['ReactNativeWebView']) {
    return false;
  }

  let message = `${event + '@b$y@'}`;

  if (payload) {
    message = `${message}${payload}`;
  }

  window['ReactNativeWebView'].postMessage(btoa(message));

  return true;
}
