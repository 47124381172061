import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { RootState } from '@app/root-store/root-state';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import {
  catchError,
  filter,
  fromEvent,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { TOKEN_REFRESH_INTERVAL } from '../../config';
import { logoutRequest } from '../../root-store/auth-store/actions';
import { loadRequest as UserMeLoadRequest } from '../../root-store/user-me-store/actions';
import { AuthService } from '../../services/auth.service';

import { TimeService } from './time.service';
import { LogoutBroadcastService } from '../../services/logout-broadcast.service';
import { PostMessageFromApp } from '@app/models';
import { emitMobileAppEvent } from '@app/helpers';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  storageKeys = {
    accessToken: 'token',
    adminAccessToken: 'admin_access_token',
  };

  private refreshing$: Subject<string>;

  private logoutBroadcast = inject(LogoutBroadcastService);

  constructor(
    private _store: Store<RootState>,
    private http: HttpClient,
    private cookieService: CookieService,
    private authService: AuthService,
    private timeService: TimeService,
    private tokenHelperService: JwtHelperService,
  ) {}

  store(token: string, key = this.storageKeys.accessToken): void {
    this.delete(key);

    const expirationDate = moment().add(1, 'year').toDate();

    expirationDate.setHours(2, 0, 0, 0);

    this.cookieService.set(
      key,
      token,
      expirationDate,
      '/',
      this.getDomain(),
      null, // Default Value
      null, // Default Value
    );
  }

  getDomain() {
    const hostnameSplitted = location.hostname.split('.');

    return hostnameSplitted.length === 3
      ? `.${hostnameSplitted[1]}.${hostnameSplitted[2]}`
      : location.hostname;
  }

  delete(tokenKey = this.storageKeys.accessToken): void {
    this.cookieService.delete(tokenKey, '/', this.getDomain());
  }

  get(tokenKey = this.storageKeys.accessToken): string {
    if (!this.cookieService.check(tokenKey)) {
      return null;
    }
    return this.cookieService.get(tokenKey);
  }

  isAdmin() {
    return this.tokenHelperService.decodeToken(this.get())?.admin;
  }

  impersonateUser(userId: number, propertyId?: number, redirect?: string) {
    return this.http.get(`user/admin/impersonate/${userId}`).pipe(
      map(({ token }: { token: string }) => {
        this.store(token);
        this._store.dispatch(
          UserMeLoadRequest({
            redirect: redirect || '/',
            skipWorkspaceSelection: true,
            selectedPropertyId: propertyId,
          }),
        );
      }),
    );
  }

  removeImpersonate() {
    const redirect = 'admin/properties';

    this.http
      .delete(`user/admin/impersonate`)
      .subscribe(({ token }: { token: string }) => {
        this.store(token);
        this._store.dispatch(
          UserMeLoadRequest({
            redirect,
          }),
        );
        this.logoutBroadcast.publish({
          type: 'remove-impersonate-in-all-tabs',
          payload: { redirect },
        });
      });
  }

  refreshTokenFromApp() {
    return of().pipe(
      switchMap(() => {
        return fromEvent<MessageEvent>(window, 'message').pipe(
          map((message) => {
            const decodedEvent = atob(message.data);
            return decodedEvent.split('@b$y@') as [PostMessageFromApp, any];
          }),
          filter(([eventName, _]) => eventName === 'setToken'),
          map(([_, payload]) => payload as string),
          take(1),
          catchError(() => {
            console.error('logout from app refreshToken');

            this._store.dispatch(
              logoutRequest({ loginRedirect: window.location.href }),
            );

            return of(null);
          }),
        );
      }),
      tap(() => {
        emitMobileAppEvent('getToken');
      }),
    );
  }

  refresh() {
    if (!!this.cookieService.get('from_mobile_app')) {
      return this.refreshTokenFromApp();
    }

    return this.authService.refresh(this.get()).pipe(
      tap((token) => {
        if (!token) {
          return;
        }

        this.store(token);
      }),
      catchError(() => {
        this._store.dispatch(
          logoutRequest({ loginRedirect: window.location.href }),
        );

        return of(null);
      }),
    );
  }

  getOrRefreshToken(): Observable<string> {
    // Recupero l'orario del server per effettuare i controlli sul token
    return this.timeService.get().pipe(
      switchMap((date) => {
        // Il token non è scaduto, non sta per scadere e non ci sono chiamate di refresh in corso
        // è possibile utilizzare il token attualmente conservato
        if (
          !this.isExpired(date) &&
          !this.isExpiring(date) &&
          (!this.refreshing$ || this.refreshing$.closed)
        ) {
          return of(this.get());
        }

        // Si deve eseguire il refresh ma nessun'altra richiesta ha avviato la sessione
        // di refresh, è necessario avviare la sessione di refresh
        if (!this.refreshing$ || this.refreshing$.closed) {
          this.refreshing$ = new Subject<string>();

          this.refresh().subscribe((token) => {
            if (token) {
              this.refreshing$.next(token);
            }

            this.refreshing$.unsubscribe();
            this.refreshing$ = null;
          });
        }

        // Esiste una sessione di refresh, appena creata dalla chiamata corrente o
        // creata da una chiamata precedente, rimaniamo in attesa che ci venga restituito il nuovo token
        return this.refreshing$.asObservable();
      }),
    );
  }

  private isExpired(date: Date): boolean {
    const token = this.get();

    try {
      const expirationDate =
        this.tokenHelperService.getTokenExpirationDate(token);

      return moment(expirationDate).isSameOrBefore(date, 'minutes');
    } catch (e) {
      return true;
    }
  }

  private isExpiring(date: Date): boolean {
    const token = this.get();

    try {
      const expirationDate =
        this.tokenHelperService.getTokenExpirationDate(token);

      const diffInMinutes = moment(expirationDate).diff(date, 'minutes');

      return diffInMinutes < TOKEN_REFRESH_INTERVAL;
    } catch (e) {
      return true;
    }
  }
}
